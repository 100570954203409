import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import styles from "./UserList.module.css";
import Accordion from "./Accordion"; // Asegúrate de que la ruta sea correcta
import Marquee from "react-fast-marquee";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Definir las marcas con sus imágenes
const brands = {
  QuickLending: { name: "Quick Lending", imageUrl: "/img/ql_logo.png" },
  QuickLendingDeluxe: {
    name: "Quick Lending Deluxe",
    imageUrl: "/img/QLDeluxe.png",
  },
  VIPHomeLoans: { name: "VIP Home Loans", imageUrl: "/img/viphomeloans.png" },
  AtlasSolutions: {
    name: "Atlas Solutions",
    imageUrl: "/img/atlasSolutions.png",
  },
  MyVirtualAssistant: {
    name: "My Virtual Assistant",
    imageUrl: "/img/MVA.png",
  },
  MyProcessingTeam: { name: "My Processing Team", imageUrl: "/img/mpt.png" },
  FlowersofCentralFlorida: {
    name: "Flowers of Central Florida",
    imageUrl: "/img/Flowers.png",
  },
  JPCapital: { name: "JP Capital", imageUrl: "/img/JPC-.png" },
  ConstructionDepot: {
    name: "Construction Depot",
    imageUrl: "/img/Construction_Depot-1.png",
  },
  TheTaxAdvisor: {
    name: "The Tax Advisor",
    imageUrl: "/img/TheTaxAdvisor.png",
  },
  // Agrega más marcas aquí
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  // Array de enlaces correspondientes a cada imagen del slider
  const [slides, setSlides] = useState([
    {
      imageUrl: "/img/ql_logo.png",
      link: "https://quicklendingusa.net/",
    },
    {
      imageUrl: "/img/atlasSolutions.png",
      link: "https://atlassolutionsmarketing.com/",
    },
    { imageUrl: "/img/viphomeloans.png", link: "https://viphomeloans.net/" },
    { imageUrl: "/img/cd.png", link: "https://constructiondepotusa.com/" },
    { imageUrl: "/img/Flowers.png", link: "https://example.com/flowers" },
    { imageUrl: "/img/JPC-.png", link: "https://jpcapitalusa.com/" },
    { imageUrl: "/img/mpt.png", link: "https://example.com/myprocessingteam" },
    {
      imageUrl: "/img/MVA.png",
      link: "https://myvirtualassistantusa.com/",
    },
    {
      imageUrl: "/img/QLDeluxe.png",
      link: "https://quicklendingdeluxe.com/",
    },
    {
      imageUrl: "/img/TheTaxAdvisor.png",
      link: "https://thetaxadvisor.net/",
    },
  ]);

  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc) => doc.data());
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Filtrar usuarios por nombre y marca
  const filteredUsers = users.filter((user) => {
    const userName = user.displayName || ""; // Usar displayName en lugar de name
    const matchesName = userName
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesBrand = selectedBrand ? user.brand === selectedBrand : true;
    return matchesName && matchesBrand;
  });

  // Agrupar usuarios filtrados por marca
  const groupedUsers = filteredUsers.reduce((acc, user) => {
    if (!acc[user.brand]) {
      acc[user.brand] = [];
    }
    acc[user.brand].push(user);
    return acc;
  }, {});

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isPaused) {
        setSlides((prevSlides) => {
          const newSlides = [...prevSlides];
          const firstSlide = newSlides.shift();
          newSlides.push(firstSlide);
          return newSlides;
        });
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  // Ordenar las marcas por cantidad de usuarios de más a menos
  const sortedBrands = Object.keys(groupedUsers).sort((a, b) => {
    return groupedUsers[b].length - groupedUsers[a].length;
  });

  return (
    <div className={styles.userListContainer}>
      <div className={styles.banner}>
        <h1>Welcome to Atlas Investments</h1>
        <p>Explore our team profiles and learn more about them.</p>

        <div className={styles.filters}>
          <input
            type="text"
            placeholder="Search by name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <select
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">All brands</option>
            {Object.keys(brands).map((brandKey) => (
              <option key={brandKey} value={brandKey}>
                {brands[brandKey].name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className={styles.slider}>
        <Marquee
          gradient={false}
          speed={120}
          pauseOnHover={true}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          {slides.map((slide, index) => (
            <div className={styles.slide} key={index}>
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                <img src={slide.imageUrl} alt={`Slide ${index}`} />
              </a>
            </div>
          ))}
        </Marquee>
      </div>

      {/* Recorre las marcas ya ordenadas */}
      {sortedBrands.map((brand) => (
        <div key={brand} className={styles.brandSection}>
          <div className={styles.brandHeader}>
            {brands[brand] ? (
              <>
                <img
                  src={brands[brand].imageUrl}
                  alt={`${brands[brand].name} logo`}
                  className={styles.brandImage}
                />
              </>
            ) : (
              <h2>{brand}</h2> // Muestra el nombre de la marca aunque no tenga imagen
            )}
          </div>

          <div className={styles.userCards}>
            {groupedUsers[brand].map((user) => (
              <div key={user.uid} className={styles.userCard}>
                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt={`${user.displayName || "No Name"}'s profile`}
                    className={styles.profilePicture}
                  />
                ) : (
                  <div className={styles.placeholder}>No Image</div>
                )}
                <h2 className={styles.name}>{user.displayName || "No Name"}</h2>
                <div className={styles.userinfo}>
                  <div className={styles.emailContainer}>
                    <p>
                      <i
                        className="bi bi-envelope-fill"
                        style={{ color: "black" }}
                      >
                        {" "}
                      </i>
                      {`${user.email || "No Email"}`}
                    </p>
                  </div>
                  <p>
                    {" "}
                    <i
                      className="bi bi-building-fill"
                      style={{ color: "black" }}
                    >
                      {" "}
                    </i>
                    {brands[brand]?.name || "No Brand"}
                  </p>{" "}
                  <p>
                    <i
                      className="bi bi-briefcase-fill"
                      style={{ color: "black" }}
                    >
                      {" "}
                    </i>
                    {`${user.position || "No Position"}`}
                  </p>{" "}
                  {/* Mostrar el campo Position */}
                  <p>
                    <i
                      className="bi bi-globe-americas"
                      style={{ color: "black" }}
                    >
                      {" "}
                    </i>
                    {`${user.country || "No Country"}`}
                  </p>{" "}
                  <p>
                    <i
                      className="bi bi-calendar2-check-fill"
                      style={{ color: "black" }}
                    >
                      {" "}
                    </i>
                    {`${user.birthday || "No Birthday"}`}
                  </p>{" "}
                  {/* Mostrar el campo Country */}
                  {/* Mostrar el nombre de la marca */}
                  <Accordion content={user.about || "No Info"} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserList;
