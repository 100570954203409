import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db, storage } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styles from "./Register.module.css";
import Background from "../background/background"; // Importar el componente Background
import { toast } from "react-hot-toast";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [brand, setBrand] = useState("");
  const [photo, setPhoto] = useState(null);
  const [position, setPosition] = useState(""); // Nuevo estado para "Cargo"
  const [country, setCountry] = useState(""); // Nuevo estado para "País"
  const [birthday, setBirthday] = useState("");
  const [about, setAbout] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const photoURL = await uploadProfilePicture(user.uid, photo);
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        displayName,
        email,
        brand,
        photoURL,
        position,
        country,
        birthday,
        about,
      });
      localStorage.setItem("isAuthenticated", "true");
      navigate("/users");
      toast("Good Job!", {
        icon: "👏",
      });
    } catch (error) {
      console.error("Error registrando usuario:", error);
      toast.error("This didn't work.");
      setError(error.message);
    }
  };

  const uploadProfilePicture = async (userId, file) => {
    if (!file) return null;
    const storageRef = ref(storage, `profilePictures/${userId}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  return (
    <div className={styles["container"]}>
      <Background />
      <div className={styles["registerContainer"]}>
        <form onSubmit={handleRegister} className={styles.registerForm}>
          <h2>Register</h2>
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <input
                type="text"
                placeholder="Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <select
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
              >
                <option value="">Choose your brand</option>
                <option value="QuickLending">Quick Lending</option>
                <option value="QuickLendingDeluxe">Quick Lending Deluxe</option>
                <option value="VIPHomeLoans">VIP Home Loans</option>
                <option value="AtlasSolutions">Atlas Solutions</option>
                <option value="MyVirtualAssistant">My Virtual Assistant</option>
                <option value="MyProcessingTeam">My Processing Team</option>
                <option value="JPCapital">JP Capital</option>
                <option value="ConstructionDepot">Construction Depot</option>
                <option value="FlowersofCentralFlorida">
                  Flowers of Central Florida
                </option>
                <option value="TheTaxAdvisor">The Tax Advisor</option>
              </select>

              <text className={styles.pickPhoto}>Choose your photo</text>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setPhoto(e.target.files[0])}
              />
            </div>
            <div className={styles.formColumn}>
              <input
                type="text"
                placeholder="Position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Birthday (MM/DD)"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                required
              />
              <textarea
                placeholder="Tell us about yourself"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                required
              />
            </div>
          </div>
          <button type="submit">Registrarse</button>
          {error && <p className={styles.error}>{error}</p>}
          <div className={styles["login-link"]}>
            <p>
              Do you already have an account?{" "}
              <Link to="/login">Log in here</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
