// components/auth/Verify.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Verify.module.css"; // Asegúrate de crear este archivo CSS para los estilos
import toast from "react-hot-toast";

const Verify = () => {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const correctPassword = "Atlas5305."; // Cambia esta contraseña por la que desees

  const handleVerify = () => {
    if (input === correctPassword) {
      localStorage.setItem("isVerified", "true");
      toast.success("Successfully!");
      navigate("/register");
    } else {
      toast.error("Incorrect password.");
      setError("Incorrect password. Please try again.");
    }
  };

  return (
    <div className={styles.verifyContainer}>
      <h2>Enter Access Code</h2>
      <input
        type="password"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter password"
        className={styles.input}
      />
      <button onClick={handleVerify} className={styles.button}>
        Verify
      </button>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default Verify;
