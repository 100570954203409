import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import styles from "./UserProfile.module.css";

const UserProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log("User ID:", id);
    const fetchUser = async () => {
      try {
        if (id) {
          const userDocRef = doc(db, "users", id); // Asegúrate de que `db` esté correctamente importado
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUser(userDoc.data());
          } else {
            console.error("No such user!");
          }
        } else {
          console.error("No user ID provided");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.userProfile}>
      <h2>{user.displayName}</h2>
      <p>Email: {user.email}</p>
      <p>Marca: {user.brand}</p>
      <img
        src={user.photoURL}
        alt="Foto de perfil"
        className={styles.profileImage}
      />
    </div>
  );
};

export default UserProfile;
