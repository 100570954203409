// Login.js
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // Importar Link desde react-router-dom
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import styles from "./Login.module.css"; // Importar módulo CSS
import Background from "../background/background"; // Importar el componente Background
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("userUID", userCredential.user.uid); // Guardar UID en localStorage
      navigate("/users");
      toast.success("Successfully!");
    } catch (error) {
      toast.error("Login failed.");
      console.error("Login failed", error);
    }
  };

  return (
    <div className={styles["container"]}>
      <Helmet>
        <title>Atlas Investments Fl</title>
        <meta name="description" content="Bienvenido a Atlas Investments FL" />
        <link rel="canonical" href="https://atlasinvestmentsfl.com/" />
      </Helmet>
      <Background />
      <div className={styles["login-container"]}>
        <form className={styles["login-form"]} onSubmit={handleSubmit}>
          <img className={styles["img-logo"]} src="/img/logo.png" alt="" />
          <div>
            <input
              className={styles["form-control"]}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div>
            <input
              className={styles["form-control"]}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <button className={styles["submit-btn"]} type="submit">
            Login
          </button>
          <div className={styles["register-link"]}>
            {/* Agregar un enlace a la página de registro */}
            <p>
              Don't have an account? <Link to="/register">Sign up here</Link>
            </p>
          </div>
        </form>
      </div>

      <div className={styles.sections}>
        {/* Sección "Who We Are" */}
        <div className={styles.section}>
          <h2>Who We Are</h2>
          <p>
            We are a dynamic team of professionals dedicated to providing
            top-notch solutions and services to our clients. Our team is
            composed of experts in various fields, all working together to
            deliver the best outcomes for our customers. We believe in
            innovation, quality, and commitment to excellence.
          </p>
        </div>
        {/* Sección "Why We Make It Different" */}
        <div className={styles.section}>
          <h2>Why We Make It Different</h2>
          <p>
            Our approach sets us apart in the industry. We focus on
            understanding the unique needs of our clients and tailoring our
            services to meet those needs. Our commitment to quality, innovation,
            and customer satisfaction drives us to constantly improve and
            evolve. We strive to exceed expectations and deliver results that
            make a difference.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
