// App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import UserList from "./components/users/UserList";
import UserProfile from "./components/users/UserProfile";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Verify from "./components/auth/Verify"; // Importar el componente Verify
import { Toaster } from "react-hot-toast";
import { auth } from "./firebase"; // Asegúrate de importar auth correctamente

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Monitorea el estado de autenticación
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true"); // Guardar en localStorage
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem("isAuthenticated"); // Remover de localStorage
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      <AppRoutes isAuthenticated={isAuthenticated} />
      <Footer />
    </Router>
  );
};

const AppRoutes = ({ isAuthenticated }) => {
  const location = useLocation();
  const isVerified = localStorage.getItem("isVerified") === "true";

  return (
    <>
      {/* Muestra el Header solo si la ruta actual no es /login, /register o /verify */}
      {location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/verify" && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />{" "}
        {/* Ruta de verificación */}
        {isVerified ? (
          <Route path="/register" element={<Register />} />
        ) : (
          <Route path="/register" element={<Navigate to="/verify" />} />
        )}
        {isAuthenticated ? (
          <>
            <Route path="/users" element={<UserList />} />
            <Route path="/profile/:id" element={<UserProfile />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
};

export default App;
